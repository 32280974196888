import type { ReactElement, ReactNode, RefObject, SyntheticEvent } from 'react'
import { clamp, memoize, throttle } from 'lodash'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ImageMagnify from 'react-image-magnify'

// https://github.com/ePages-de/epages-storage/blob/v0.10.12/src/universal/conf/application.conf#L61
const maxStorageDimension = 2560

// Allowed widths to be used in the "srcset" attribute
// (= lazysizes RIaS plugin default value reduced to the epages-storage maximum dimension)
const srcSetWidths = [180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 1944, 2160, 2376, maxStorageDimension]

// Used to get the responsive image’s "srcset" attribute value.
// Constrains the used widths to be up to "maxWidth" (if any).
const getSrcSet = memoize((src: string, maxWidth?: number) => {
  const widths = maxWidth ? srcSetWidths.filter((w) => w <= maxWidth) : srcSetWidths

  if (maxWidth && maxWidth < maxStorageDimension && Math.max.apply(null, widths) < maxWidth) {
    widths.push(maxWidth)
  }

  return widths.map((w) => `${src}&width=${w}&height=${maxStorageDimension} ${w}w`).join(',')
})

interface ZoomAppearance {
  // Image container width (used for setting the responsive image’s "sizes" attribute value)
  containerWidth: number
  // Enlarged image position (used with react-image-magnify)
  enlargedImagePosition: 'over' | 'beside'
}

// Used to get appearance values.
function useZoomAppearance<T extends HTMLElement>(priorityContainerSelector?: string): [ZoomAppearance, RefObject<T>] {
  const containerRef = useRef<T>(null)
  const [appearance, setAppearance] = useState<ZoomAppearance>({ containerWidth: 0, enlargedImagePosition: 'over' })

  useLayoutEffect(() => {
    const priorityElement = priorityContainerSelector && document.querySelector(priorityContainerSelector)

    function updateAppearance() {
      const element = priorityElement || containerRef.current
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { width, right } = element!.getBoundingClientRect()

      setAppearance({
        containerWidth: width,
        // Choose "over" if the space on the right of the element isn’t sufficient
        // (less than element width plus enlarged image element offset/page scroll bar width).
        enlargedImagePosition: window.innerWidth - right < width + 50 ? 'over' : 'beside',
      })
    }

    updateAppearance()

    // Note: The wait ms value should be lower than the timeout ms value in `useDeferredValue`.
    // so that the appearance update happens before the image height update.
    const handleWindowResize = throttle(updateAppearance, 200)
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      handleWindowResize.cancel()
    }
  }, [priorityContainerSelector])

  return [appearance, containerRef]
}

// Used to get the current small image client height for setting it as a CSS Custom Property (see its usage).
// Deferred with intermediate "reset" to get the accurate value upon HTML responsive image change
// (e.g. resizing, updates due to "sizes" attribute value change).
function useDeferredValue<T>(): [T | undefined, (fn: () => T) => void] {
  const timeoutRef = useRef<number>()
  const [value, setValue] = useState<T>()

  function deferredUpdate(getNextValue: () => T) {
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current)

    // Reset while updating
    if (value !== undefined && !timeoutRef.current) setValue(undefined)

    // Deferred update
    // Note: The timeout ms value should be greater than the `throttle` wait ms value in `useZoomAppearance`.
    // so that the deferred update happens after the element resize zoom appearance update.
    timeoutRef.current = window.setTimeout(() => {
      setValue(getNextValue())
      timeoutRef.current = undefined
    }, 300)
  }

  return [value, deferredUpdate]
}

// Used to activate image zoom functionality after the first "mousemove" event,
// or immediately on touch-only devices.
function useActivated() {
  const [activated, setActivated] = useState(false)

  useEffect(() => {
    function activate() {
      setActivated(true)
      window.removeEventListener('mousemove', activate)
    }

    window.addEventListener('mousemove', activate)

    // Immediately activate on touch-only devices.
    // `any-hover: none` means that "none of the available input mechanism(s)
    // can hover conveniently, or there is no pointing input mechanism".
    if (window.matchMedia('(any-hover: none)').matches) activate()

    return () => window.removeEventListener('mousemove', activate)
  }, [])

  return activated
}

interface ZoomableImage {
  src: string
  width: number
  height: number
  alt: string

  // DOM element ID to portal the enlarged image into if its position is "beside":
  enlargedImagePortalId?: string

  // DOM element CSS selector for appearance measurements:
  imageContainerSelector?: string

  // Optional prop to indicate whether the image is currently in view:
  // - affects zoomability, defaults to `true`
  // - currently used in image gallery slider for product pages
  isInView?: boolean

  // Optional prop to place content overlayed on the zoomable image.
  // Note that this content can not be interacted with (e.g. clicked on).
  // It is currently used for product label badges.
  overlayContent?: ReactNode
}

export default function ZoomableImage({
  src,
  width,
  height,
  alt,
  enlargedImagePortalId,
  imageContainerSelector,
  overlayContent,
  isInView = true,
}: ZoomableImage): ReactElement {
  const [appearance, containerRef] = useZoomAppearance<HTMLDivElement>(imageContainerSelector)
  const isActivated = useActivated()
  const [hasImageLoaded, setHasImageLoaded] = useState(false)

  // Determine whether the image can be enlarged.
  // The image can be enlarged when all of these three conditions are met:
  // 1. The image is indicated to be in view
  // 2. The large image size exceeds the display area size
  // 3. The image zoom threshold is met (no zoom if only a little larger than the display area size)
  const canBeEnlarged = Boolean(
    isInView &&
      appearance.containerWidth &&
      containerRef.current &&
      (width > containerRef.current.clientWidth * 1.5 ||
        // Extra "clientHeight" check because it’s `0` if the container element doesn’t have a fixed height.
        (containerRef.current.clientHeight && height > containerRef.current.clientHeight * 1.5)),
  )

  // Prevent unwanted image upscaling in the browser (when the image is smaller than its container).
  const [smallImageMaxWidth, setSmallImageMaxWidth] = useState<number | undefined>(width)
  const smallImageStyle = { maxWidth: smallImageMaxWidth }

  // Setting the current small image client height as a CSS Custom Property is needed for the fallback
  // rules in epages.base "image-gallery.less" (to achieve a correct zoom lens and image zoom effect
  // with react-image-magnify).
  //
  // - Set the current small image client height as a CSS Custom Property on the element.
  // - If not yet known, set its value to `100%` (e.g. initially and upon resizing).
  // - See epages.base "image-gallery.less" for details.
  const [smallImageHeight, updateSmallImageHeight] = useDeferredValue<number>()

  // Props that are used with the zoomable and non-zoomable small image.
  const smallImageProps = {
    src: `${src}&width=600&height=${maxStorageDimension}`,
    srcSet: getSrcSet(src, width),
    sizes: `${appearance.containerWidth}px`,
    alt: alt || '',
    onLoad: (event: SyntheticEvent<HTMLImageElement>) => {
      if (!hasImageLoaded) setHasImageLoaded(true)

      // Determine the current intrinsic small image height.
      const smallImageElement = event.currentTarget
      updateSmallImageHeight(() => Math.min(smallImageElement.clientHeight, height))

      // Set the the small image max width to prevent unwanted upscaling in the browser.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSmallImageMaxWidth(width <= containerRef.current!.clientWidth ? width : undefined)
    },
  }

  return (
    <div
      ref={containerRef}
      className="ep-zoomable-image"
      data-enlarged-image-position={canBeEnlarged ? appearance.enlargedImagePosition : null}
      style={{
        ['--small-image-height' as string]: smallImageHeight ? `${smallImageHeight}px` : '100%',
        pointerEvents: isActivated ? undefined : 'none',
      }}
    >
      {canBeEnlarged ? (
        <ImageMagnify
          key={appearance.enlargedImagePosition}
          className="image-magnify"
          enlargedImagePosition={appearance.enlargedImagePosition}
          enlargedImagePortalId={appearance.enlargedImagePosition === 'beside' ? enlargedImagePortalId : null}
          enlargedImageContainerClassName="image-magnify-enlarged"
          isEnlargedImagePortalEnabledForTouch={true}
          imageClassName="image-magnify-small"
          imageStyle={smallImageStyle}
          smallImage={{ isFluidWidth: true, ...smallImageProps }}
          largeImage={{ src, width, height }}
          lensComponent={CustomLens}
        />
      ) : (
        <div className="image-magnify">
          {smallImageProps.sizes !== '0px' && (
            // Don't render any img tag until sizes are properly computed,
            // or else the browser would download the smallest available size (180px wide).
            //
            // "alt" attribute provided with `...smallImageProps`
            // eslint-disable-next-line jsx-a11y/alt-text
            <img className="image-magnify-small" style={smallImageStyle} {...smallImageProps} />
          )}
        </div>
      )}
      {overlayContent && hasImageLoaded && (
        <div
          className="ep-zoomable-image-overlay"
          style={{
            aspectRatio: `${width} / ${height}`,
            maxWidth: width,
            maxHeight: height,
            width: width > height ? '100%' : undefined,
            height: height >= width ? '100%' : undefined,
          }}
        >
          {overlayContent}
        </div>
      )}
    </div>
  )
}

interface CustomLens {
  cursorOffset: {
    x: number
    y: number
  }
  fadeDurationInMs: number
  isActive: boolean
  isPositionOutside: boolean
  position: {
    x: number
    y: number
  }
  smallImage: {
    height: number
    width: number
  }
}

function CustomLens({
  cursorOffset,
  fadeDurationInMs,
  isActive,
  isPositionOutside,
  position,
  smallImage,
}: CustomLens): ReactElement {
  const isVisible = isActive && !isPositionOutside
  const width = cursorOffset.x * 2
  const height = cursorOffset.y * 2
  const top = position.y - cursorOffset.y
  const left = position.x - cursorOffset.x
  const maxTop = smallImage.height - height
  const maxLeft = smallImage.width - width

  return (
    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, overflow: 'hidden' }}>
      <div
        style={{
          width,
          height,
          opacity: isVisible ? 1 : 0,
          transition: `opacity ${fadeDurationInMs}ms ease-in`,
          boxShadow: '0 0 0 9999px rgba(0,0,0,.4)',
          position: 'absolute',
          top: 0,
          left: 0,
          willChange: 'transform',
          transform: `translate3d(${clamp(left, 0, maxLeft)}px, ${clamp(top, 0, maxTop)}px, 0)`,
        }}
      />
    </div>
  )
}
