import type { PropsWithChildren } from 'react'
import { useDispatch } from 'react-redux'
import cc from 'classcat'

import { setIsInWishlist } from '../../store/actions'
import { useProductDetailState } from './ProductDetailProvider'
import TemplateAddToWishlistLink from '../templateComponents/AddToWishlistLink'

export interface AddToWishlistLinkProps {
  productId: string
  isCustomizableProduct: boolean
  title: string
  isInWishlist?: boolean
}

export default function AddToWishlistLink({
  children,
  productId,
  isCustomizableProduct,
  isInWishlist = false,
  title,
}: PropsWithChildren<AddToWishlistLinkProps>) {
  const { customizedText } = useProductDetailState()
  const dispatch = useDispatch()

  const callAfterItemAddedToWishlist = () => {
    dispatch(setIsInWishlist(productId, true))
  }

  return (
    <TemplateAddToWishlistLink
      title={title}
      className={cc(['product-add-wishlist-link', { 'is-in-wishlist': isInWishlist }])}
      productId={productId}
      isCustomizableProduct={isCustomizableProduct}
      isInWishlist={isInWishlist}
      customizedText={customizedText}
      callAfterItemAddedToWishlist={callAfterItemAddedToWishlist}
    >
      {children}
    </TemplateAddToWishlistLink>
  )
}
