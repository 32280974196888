import type { ReactElement } from 'react'

import AddToCartButton from '../../productDetails/AddToCartButton'
import AddToWishlistLink from '../../productDetails/AddToWishlistLink'
import CustomizableTextField from '../../productDetails/CustomizableTextField'
import QuantityField from '../../productDetails/QuantityField'
import QuantityFieldErrors from '../../productDetails/QuantityFieldErrors'
import ShareButton from '../../templateComponents/ShareButton'
import VariationSelection from '../../templateComponents/VariationSelection'

type Props = {
  product: Frontend.Product
  shop: Frontend.Shop
} & TranslateProps

export default function Buybox({ t, product, shop }: Props): ReactElement {
  const isBeyondShop = Boolean(shop.beyond)

  return (
    <div className="product-info-buybox">
      {!product.isVariationMaster && (product.stockLevelClass || product.deliveryPeriod) && (
        <div className="stocklevel">
          {product.stockLevelClass && (
            <div className="product-availability show-stock-level">
              {product.availabilityText.includes('NOT_AVAILABLE') ? (
                <strong className="product-availability-info">{t(product.availabilityText)}</strong>
              ) : (
                <>
                  <span className={`product-availability-icon ${product.stockLevelClass}-stock-icon`} />
                  <span className="product-availability-info">{t(product.availabilityText)}</span>
                </>
              )}
            </div>
          )}
          {product.deliveryPeriod && !product.availabilityText.includes('NOT_AVAILABLE') && (
            <div className="product-delivery">
              {t('components.productComponent.productDeliveryPeriod', {
                period: product.deliveryPeriod,
                unit: product.deliveryPeriodUnit,
              })}
            </div>
          )}
          {product.pickupPeriod && !product.availabilityText.includes('NOT_AVAILABLE') && (
            <div className="product-pickup">
              {t('components.productComponent.productPickupPeriod', {
                period: product.pickupPeriod,
                unit: product.pickupPeriodUnit,
              })}
            </div>
          )}
        </div>
      )}
      {(product.variations || {}).items && (
        <div className="variations-attribute">
          <VariationSelection product={product} key={product.slug} />
        </div>
      )}

      {((!product.isVariationMaster && !isBeyondShop) || isBeyondShop) && product.customizableProductInfo && (
        <CustomizableTextField t={t} customizableProductInfo={product.customizableProductInfo} />
      )}

      {!product.isVariationMaster && (
        <>
          <QuantityField min={1} className="product-quantity-field" orderUnitInfo={product.orderUnitInfo} />
          <QuantityFieldErrors orderUnitInfo={product.orderUnitInfo} t={t} locale={shop.locale} />
        </>
      )}

      <div className="product-button-container">
        {!isBeyondShop && !product.isVariationMaster && (
          <AddToWishlistLink
            productId={product.productId}
            isCustomizableProduct={Boolean(product.customizableProductInfo)}
            isInWishlist={product.isInWishlist}
            title={t('components.productComponent.addToWishlistLink.label')}
          >
            <span>{t('components.productComponent.addToWishlistLink.label')}</span>
          </AddToWishlistLink>
        )}
        <AddToCartButton className="product-add-cart-button" product={product} t={t}>
          {t('components.productComponent.addToBasketButton.label')}
        </AddToCartButton>
        {isBeyondShop && shop.socialSharingEnabled && (
          <ShareButton t={t} product={product} shopBaseUrl={new URL(shop.beyond.baseUrl)} />
        )}
      </div>
    </div>
  )
}
