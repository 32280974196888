import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { noop, uniqueId } from 'lodash'
import PropTypes from 'prop-types'
import cc from 'classcat'

import { setThemeNotification } from '../../store/actions'
import { track } from '../../utils/tracking'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import withI18n from '../../components/withI18n'

export function AddToWishlistLinkRaw({
  children,
  className,
  locale,
  setThemeNotification,
  productId,
  quantity = 1,
  lineItemId,
  t,
  callAfterItemAddedToWishlist = noop,
  title,
  customizedText = null,
  isCustomizableProduct = false,
  isInWishlist = false,
}) {
  const [isWishlistBusy, setIsWishlistBusy] = useState(false)

  const isCustomizationMissing = isCustomizableProduct && !customizedText

  useEffect(() => {
    const handleWishlistSuccess = (event) => {
      const notification = {
        id: uniqueId(`${productId}-success-`),
        message: (
          <span>
            {t('notifications.productAddedToWishlist')}{' '}
            <a className="add-to-cart-notice-message-link" href={event.detail.wishlist} key={productId}>
              {t('views.storefrontView.goToWishlistLink.label')}
            </a>
          </span>
        ),
        type: 'success',
      }
      callAfterItemAddedToWishlist()
      setThemeNotification(notification)
      setIsWishlistBusy(false)
    }
    const handleWishlistNoUser = (event) => {
      window.addEventListener('pagehide', () => setIsWishlistBusy(false))
      window.location.assign(event.detail.redirect)
    }

    window.eComEventTarget.addEventListener(`wishlist:success-${lineItemId ?? productId}`, handleWishlistSuccess)
    window.eComEventTarget.addEventListener(`wishlist:nouser-${lineItemId ?? productId}`, handleWishlistNoUser)

    return () => {
      window.eComEventTarget.removeEventListener(`wishlist:success-${lineItemId ?? productId}`, handleWishlistSuccess)
      window.eComEventTarget.removeEventListener(`wishlist:nouser-${lineItemId ?? productId}`, handleWishlistNoUser)
    }
  }, [productId, lineItemId, t, setThemeNotification, callAfterItemAddedToWishlist])

  const addToWishlist = () => {
    setIsWishlistBusy(true)

    track('wishlist:add', {
      locale,
      product: {
        productId,
        quantity,
        customizedText: isCustomizableProduct ? customizedText : null,
      },
      lineItemId,
    })
  }

  return (
    <button
      className={cc([className, { pending: isWishlistBusy }])}
      disabled={isWishlistBusy || isCustomizationMissing}
      onClick={addToWishlist}
      type="button"
      title={title}
      aria-label={title}
    >
      {children}
    </button>
  )
}

AddToWishlistLinkRaw.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isWishlistBusy: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  setThemeNotification: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  lineItemId: PropTypes.string,
  t: PropTypes.func.isRequired,
  callAfterItemAddedToWishlist: PropTypes.func,
  title: PropTypes.string,
  customizedText: PropTypes.string,
  isCustomizableProduct: PropTypes.bool,
  isInWishlist: PropTypes.bool,
}

export default compose(
  withI18n('shop'),
  translate(),
  connect(
    (state) => ({
      locale: state.getIn(['shop', 'locale']),
    }),
    { setThemeNotification },
  ),
)(AddToWishlistLinkRaw)
