import PropTypes from 'prop-types'
import React from 'react'

const YoutubePauseCommand = JSON.stringify({ event: 'command', func: 'pauseVideo' })
const VimeoPauseCommand = JSON.stringify({ method: 'pause' })

function VideoSlide({ slide: video, isInView }) {
  const videoPlayerRef = React.useRef()
  const isYouTube = video.type === 'YouTube'

  const url = isYouTube ? `${video.url}&enablejsapi=1` : `${video.url}&api=1`

  React.useEffect(() => {
    if (videoPlayerRef.current && !isInView)
      videoPlayerRef.current.contentWindow.postMessage(isYouTube ? YoutubePauseCommand : VimeoPauseCommand, '*')
  }, [isInView, isYouTube])

  return (
    <iframe
      ref={videoPlayerRef}
      className="dali-plugin-video-content"
      src={url}
      frameBorder="0"
      allowFullScreen={true}
    />
  )
}

VideoSlide.propTypes = {
  slide: PropTypes.object.isRequired,
  isInView: PropTypes.bool.isRequired,
}

export default VideoSlide
