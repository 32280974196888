import type { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import VariationSelection from '../templateComponents/VariationSelection'

export interface BuyboxProps {
  product: Frontend.Product
  children?: ReactNode
}

export default function Buybox({ product, children }: Readonly<BuyboxProps>) {
  const isEcommerceDisabled = useSelector<State, boolean>((state) =>
    Boolean(
      state
        .getIn(['shop', 'attributes'])
        .find((attribute) => attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true'),
    ),
  )

  if (isEcommerceDisabled) {
    // The variation selection is part of the Buybox, but should still be
    // shown when e-commerce is disabled.
    if (product.variations?.items) {
      return (
        <div className="variations-attribute">
          <VariationSelection product={product} key={product.slug} />
        </div>
      )
    }

    return null
  }

  return <>{children}</>
}
