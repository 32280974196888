import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import ProductListSwiper, { isInitialPageLoad } from '../ProductListSwiper'
import { loadProductsForCrossSelling } from '../../store/actions'
import { sendCrossSellingImpressions, trackProductClick } from '../../utils/googleAnalytics'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import withI18n from '../../components/withI18n'

export class CrossSellingBarRaw extends React.Component {
  static propTypes = {
    renderProductItem: PropTypes.func.isRequired,
    renderEmptyProductItem: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    crossSellingProducts: PropTypes.object,
    loadProducts: PropTypes.func.isRequired,
    breakpoints: PropTypes.array,
    sellingCountryId: PropTypes.number,
  }

  loadProducts(pagerOptions) {
    const { product, loadProducts, sellingCountryId } = this.props

    // catch the error and handle it in the wrapper to prevent the enclosing jsx from being rendered
    // as if the initial load fails, we do not know how many slides to render and without switching slidegroups it is impossible to recover
    return loadProducts(product.productId, pagerOptions, sellingCountryId).catch(
      () => isInitialPageLoad(pagerOptions) && this.setState({ error: true }),
    )
  }

  state = {
    error: false,
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // during navigation product -> product reset error state
    if (nextProps.product.productId !== this.props.product.productId) {
      this.setState({ error: false })
    }
  }

  render() {
    const { crossSellingProducts, renderProductItem, renderEmptyProductItem, product, breakpoints } = this.props
    const { error } = this.state
    const trackCrossSellingProductClick = trackProductClick(['cross-selling', product.name])

    if (
      !product.hasCrossSelling ||
      (crossSellingProducts && crossSellingProducts.totalNumberOfProducts === 0) ||
      error
    ) {
      return null
    }

    return (
      <div className="product-cross-selling-wrapper product-slider">
        <div className="product-cross-selling-headline">
          {crossSellingProducts && crossSellingProducts.manualCrossSellingTitle}
        </div>
        <div className="product-cross-selling-list">
          <ProductListSwiper
            productList={crossSellingProducts}
            loadProducts={this.loadProducts.bind(this)}
            renderProductItem={(product, index) => renderProductItem(product, index, trackCrossSellingProductClick)}
            renderEmptyProductItem={renderEmptyProductItem}
            uniqueId={product.productId}
            breakpoints={breakpoints}
            trackProductList={sendCrossSellingImpressions(product.name)}
          />
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const crossSellingProducts = state.getIn(['crossSellingProductData', ownProps.product.productId])

  return {
    crossSellingProducts: crossSellingProducts && crossSellingProducts.toJS(),
    sellingCountryId: state.getIn(['shop', 'sellingCountryId']) || null,
  }
}

export const mapActionsToProps = (dispatch) => {
  return {
    loadProducts: (productId, query = {}, sellingCountryId) =>
      dispatch(loadProductsForCrossSelling(productId, query, sellingCountryId, { showErrorNotification: false })),
  }
}

export default compose(withI18n('shop'), translate(), connect(mapStateToProps, mapActionsToProps))(CrossSellingBarRaw)
